<template>
	<Transition name="fade">
		<DefaultSection v-if="getBannerComponent(campaignKey)">
			<component
				:is="getBannerComponent(campaignKey)"
				v-bind="{
					'banner-title':
						campaignKey === 'blackfriday_sales'
							? campaignTitle
							: '',
					'promo-code': campaignPromoCode,
				}"
			/>
		</DefaultSection>
	</Transition>
</template>

<script setup>
import DefaultSection from '@/components/sections/DefaultSection.vue'
import { useCampaignsStore } from '@/stores/campaigns'

const campaigns = useCampaignsStore()

const campaignKey = computed(() => campaigns.getPrimaryCampaignKey)

const campaignTitle = computed(
	() => campaigns.campaigns?.free_upfront_fee?.badge
)

const campaignPromoCode = computed(
	() => campaigns.campaigns?.free_upfront_fee?.promo
)
</script>
